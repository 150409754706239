<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/m2.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">Stephen Mistele</h1>
          <div class="text-center">
            <a
              href="https://www.linkedin.com/in/stephenmistele/"
              class="btn btn-primary btn-icon btn-round"
              target="_blank"
            >
              <i class="fab fa-linkedin"></i>
            </a>
            <a
              href="https://github.com/StephenMistele"
              class="btn btn-primary btn-icon btn-round"
              target="_blank"
            >
              <i class="fab fa-github"></i>
            </a>
            <a
              href="https://www.instagram.com/tall_stephen/"
              class="btn btn-primary btn-icon btn-round"
              target="_blank"
            >
              <i class="fab fa-instagram"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Hi, I'm Stephen. Nice to meet you.</h2>
            <h5 class="description">
              The only thing I love more than programming is sharing my passion
              with others. It's probably why I spent my afternoons in High
              School teaching Scratch to classrooms full of students. Since
              then, I've led Hackathon teams, made websites and mobile apps, and
              even started my own buisness. <br />
              Check out some of my projects!
              <!--PANTERIX Founder&CEO. API+Backend
              Engineer. Published Author. Website Developer. Pantent Holder.
              Cloud Database Engineer. Teacher. Mobile App Developer. Project
              Manager. -->
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
      </div>
    </div>
    <div
      class="row row-cols-1 row-cols-md-4 row-cols-sm-1 g-4 text-center justify-content-center"
    >
      <div class="col">
        <a href="#/Hackathon">
          <div class="card h-85">
            <img src="img/quickbites.jpg" class="card-img-top" alt="..." />
            <div class="card-body">
              <h5 class="card-title">Hackathon Projects</h5>
              <p class="card-text">
                I love Hackathons. Most recently, I won Microsoft's 'Best Use of
                Azure' prize at H4H 2021. Check out some of my projects!
              </p>
            </div>
            <div class="card-footer"></div>
          </div>
        </a>
      </div>
      <div class="col">
        <a href="#/Panterix">
          <div class="card h-85">
            <img src="img/Panterixpic.jpg" class="card-img-top" alt="..." />
            <div class="card-body">
              <h5 class="card-title">PANTERIX</h5>
              <p class="card-text">
                PANTERIX is a passion project turned buisness. We created a
                product to rank roads based on how dangerous they are.
              </p>
            </div>
            <div class="card-footer"></div>
          </div>
        </a>
      </div>
      <div class="col">
        <a href="#/Career">
          <div class="card h-85">
            <img src="img/Careerplanning.jpg" class="card-img-top" alt="..." />
            <div class="card-body">
              <h5 class="card-title">Career Development</h5>
              <p class="card-text">
                I've always had a passion for helping fellow students learn real
                world programming skills. I put together this site to help.
              </p>
            </div>
            <div class="card-footer"></div>
          </div>
        </a>
      </div>
    </div>
    <div
      class="row row-cols-1 row-cols-md-4 row-cols-sm-1 g-4 text-center justify-content-center"
    >
      <div class="col">
        <a href="#/Papers">
          <div class="card h-85">
            <img src="img/paper.jpg" class="card-img-top" alt="..." />
            <div class="card-body">
              <h5 class="card-title">Research Papers</h5>
              <p class="card-text">
                I co-authored a research paper on my work at Panterix, and had
                the opportunity to present my work at tech conferences.
              </p>
            </div>
            <div class="card-footer"></div>
          </div>
        </a>
      </div>
      <div class="col">
        <a href="#/ACM">
          <div class="card h-85">
            <img src="img/acmsite.jpg" class="card-img-top" alt="..." />
            <div class="card-body">
              <h5 class="card-title">ACM Sites</h5>
              <p class="card-text">
                As Webmaster of SCU ACM's board, I'm responsible for making sure
                their websites look good and are up to date.
              </p>
            </div>
            <div class="card-footer"></div>
          </div>
        </a>
      </div>
      <div class="col">
        <a href="#/Internships">
          <div class="card h-85">
            <img src="img/internships.jpg" class="card-img-top" alt="..." />
            <div class="card-body">
              <h5 class="card-title">Internships</h5>
              <p class="card-text">
                I've had a few opportunities to garner real world experience.
                Let me walk you through some of the things I've worked on.
              </p>
            </div>
            <div class="card-footer"></div>
          </div>
        </a>
      </div>
    </div>
    <div class="section section-team text-center">
      <div class="container">
        <h2 class="title">About Me</h2>
        <div class="team">
          <div class="row">
            <div class="col-md-10 offset-md-1 offset-lg-1">
              <div class="team-player">
                <img
                  src="img/stephen.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Stephen Mistele</h4>
                <p class="category text-primary">
                  Santa Clara University, Class of 2023
                </p>
                <p class="description">
                  I'm a very outgoing and outdoorsy person. When I'm not
                  programming, I love tennis, hiking, teaching, and the
                  occasional watersports. My passion for teaching is part of
                  what makes me a good leader, and it's why I've taken a
                  leadership role in every project listed above. As for my tech
                  stack, I've spent notable time working frontend in Vue, React,
                  and React Native, as well as Bootstrap, HTML and CSS. However,
                  I've found that I'm especially passionate about working
                  backend in everything from C++ to SQL, with favorite
                  technologies being ASP.NET API's in C#, and working with cloud
                  databases in Azure Cosmos DB and AWS RDS. Feel free to check
                  out my <a href="img/Resume.pdf" target="_blank">Resume</a>,
                  connect with me on
                  <a
                    href="https://www.linkedin.com/in/stephenmistele/"
                    target="_blank"
                    >LinkedIn</a
                  >, or contact me below!
                </p>
                <a
                  href="https://www.linkedin.com/in/stephenmistele/"
                  class="btn btn-primary btn-icon btn-round"
                  target="_blank"
                >
                  <i class="fab fa-linkedin"></i>
                </a>
                <a
                  href="https://github.com/StephenMistele"
                  class="btn btn-primary btn-icon btn-round"
                  target="_blank"
                >
                  <i class="fab fa-github"></i>
                </a>
                <a
                  href="https://www.instagram.com/tall_stephen/"
                  class="btn btn-primary btn-icon btn-round"
                  target="_blank"
                >
                  <i class="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">Want to get in touch?</h2>
        <p class="description">
          I love meeting new people. Feel free to reach out!
        </p>
        <div class="row">
          <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
            <a
              href="mailto:meetstephenmistele@gmail.com"
              class="btn btn-primary btn-round btn-lg"
            >
              <b>Send Message</b>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 <script>
import { Button, FormGroupInput } from "@/components";
export default {
  name: "landing",
  bodyClass: "landing-page",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        firstName: "",
        email: "",
        message: "",
      },
    };
  },
};
</script>
 <style></style>